<template>
  <div class="setting_form deep-links clear">
    <div class="white_box">
      <div class="box_inner">
        <div class="box_head d-flex align-items-center">
          <div class="left">
            <h2>White Label</h2>
            <p>White-label software providers offer a quick and impressive way to get your name in front of potential clients. When clients and potential viewers see your name on software, your business becomes trustworthy and reliable. Credibility helps you expand very fast.
            </p>
          </div>
        </div>
        <div class="settings_component">
          <div class="container ">
            <div class="settings_component_inner setting_page d-flex align-items-start">
              <div class="setting-sidebar">
                <div class="title">Agency Settings</div>
                <ul class="mt-3">
                  <li>
                    <i v-if="isSectionHasIssue('general_settings')"
                       class="fal text-danger font-weight-bold float-r fa-exclamation-triangle"
                       v-tooltip="'Incomplete/Invalid Details'"></i>
                    <router-link :to="{name:'general-settings'}">
                      <i class="far fa-cogs"></i>
                      <span>General Settings</span>
                    </router-link>
                  </li>
                  <li>
                    <i v-if="isSectionHasIssue('domain_settings')"
                       class="fal text-danger font-weight-bold float-r fa-exclamation-triangle"
                       v-tooltip="'Incomplete/Invalid Details'"></i>
                    <router-link :to="{name:'domain-settings'}">
                      <i class="far fa-globe"></i>
                      <span>Domain Settings</span>
                    </router-link>
                  </li>
                  <li>
                    <i v-if="isSectionHasIssue('email_settings')"
                       class="fal text-danger font-weight-bold float-r fa-exclamation-triangle"
                       v-tooltip="'Incomplete/Invalid Details'"></i>
                    <router-link :to="{name:'email-settings'}">
                      <i class="far fa-envelope"></i>
                      <span>Email Settings</span>
                    </router-link>
                  </li>
                </ul>
              </div>

              <transition name="slide">
                <router-view></router-view>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default ({
  name: 'WhitelabelSettings',
  computed: {
    ...mapGetters(['getWhiteLabelSectionValidate', 'getWhiteLabel'])
  },
  async created () {
    await this.fetchDomains()
    await this.setWhiteLabel(null)
    await this.resetWhitelabelValidations()
    await this.fetchWhiteLabel()
    this.getSiteDetails
  },
  methods: {
    ...mapActions(['setWhiteLabel', 'resetWhitelabelValidations', 'fetchWhiteLabel', 'fetchDomains']),
    isSectionHasIssue (key = '') {
      if (key === 'general_settings') {
        return (
          this.getWhiteLabelSectionValidate.agency_name ||
          this.getWhiteLabelSectionValidate.header_logo ||
          this.getWhiteLabelSectionValidate.favicon ||
          this.getWhiteLabelSectionValidate.privacy_policy ||
          this.getWhiteLabelSectionValidate.terms_of_service ||
          this.getWhiteLabelSectionValidate.login_logo ||
          this.getWhiteLabelSectionValidate.app_loader
        )
      }

      if (key === 'domain_settings') {
        return this.getWhiteLabelSectionValidate.white_label_domain || this.getWhiteLabelValidations.whiteLabel_domain_connected || this.getWhiteLabelSectionValidate.custom_domain_id
      }

      if (key === 'branded_domain') {
        return this.getWhiteLabelSectionValidate.custom_domain_id
      }

      if (key === 'email_settings') {
        return this.getWhiteLabelSectionValidate.email_validate || this.getWhiteLabelValidations.is_email_connected
      }

    }
  }
})
</script>
<style lang="less" scoped>
.deep-links {
  .white_box {
    .box_inner {
      .box_head {
        border-bottom: 1px solid #d0d6e5;
      }

      .settings_component {
        background: white;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .container {
          .settings_component_inner {
            padding: 0px 10px;

            .setting-sidebar {
              .title {
                font-size: small;
                margin-top: 28px;
                letter-spacing: 0.7px;
              }

              .search_input_block {
                overflow: unset;

                input {
                  background: none;
                  padding: 8px 15px;
                  border: solid 1px #d0d6e5;
                }

                margin-bottom: 20px;
              }

              ul {
                width: 90%;

                li {
                  a.active_tab {
                    background-color: rgba(69, 162, 255, 0.1);
                    border: none;

                    i, span {
                      color: #1d8bfa;
                      font-weight: bold;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

</style>
